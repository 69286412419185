import React, { useEffect, useState } from 'react';

const SocketComponent: React.FC = () => {
  const [status, setStatus] = useState<string>('Initializing...');

  useEffect(() => {
    const startSocketServer = async () => {
      try {
        const response = await fetch("https://tce-s2tt-voice-to-form.tcecluster-ca-tor-1-bx2-4-9661ffaebbc7c03d8d459b75277e85ab-0000.ca-tor.containers.appdomain.cloud/start-socket-server", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Cookie': 'edb1011731baddf8fddf7d956c880305=ab78a1898d84c7f885fb96db4f1f411b',
            'Access-Control-Allow-Origin': '*'
          },
          redirect: 'follow',
          credentials: 'include', // Ensure cookies are included
        });

        if (response.ok) {
          const result = await response.text();
          console.log(result);
        } else {
          console.log(`Failed to start socket server. Status: ${response.status}`);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };


    const connectSocket = async () => {
      const socket = new WebSocket('5b6df322-ca-tor.lb.appdomain.cloud');

      socket.onopen = () => {
        setStatus('Socket connected successfully.');
      };

      socket.onmessage = (event) => {
        console.log('Message from server:', event.data);
      };

      socket.onerror = (error) => {
        setStatus(`Socket error: ${error}`);
      };

      socket.onclose = () => {
        setStatus('Socket closed.');
      };
    };

    startSocketServer();

    return () => {
      // Clean up WebSocket connection if needed
    };
  }, []);

  return <div>Status: {status}</div>;
};

export default SocketComponent;
