import React, { useEffect, useRef, useState } from 'react';


export default function ReportGeneration() {
  const divStyle = {
    color: '#000',
    zIndex: 1000,
  };

  let mediaRecorder: MediaRecorder | null = null;
  let audioChunks: Blob[] = [];

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.addEventListener('dataavailable', event => {
          if (event.data.size > 0) {
            audioChunks.push(event.data);
          }
        });
        mediaRecorder.addEventListener('stop', () => {
          const audioBlob = new Blob(audioChunks);
          sendAudioToServer(audioBlob, 'eng', 'eng');
          audioChunks = [];
        });

        mediaRecorder.start(); // Start recording when the component mounts
      })
      .catch(error => {
        console.error('Error accessing microphone:', error);
      });

    return () => {
      if (mediaRecorder && mediaRecorder.state !== 'inactive') {
        mediaRecorder.stop(); // Stop recording when the component unmounts
      }
    };
  }, []);

  const sendAudioToServer = (audioBlob: Blob, slanguage: string, tlanguage: string) => {
    const formData = new FormData();
    formData.append('audio_blob', audioBlob);
    formData.append('slanguage', slanguage);
    formData.append('tlanguage', tlanguage);

    fetch('https://echo.estecharat.com/process_audio', {
      method: 'POST',
      body: formData,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        processText(data.result);
        console.log('Prediction:', data.result || 'Prediction not available');
      })
      .catch(error => {
        console.error('Error sending audio to server:', error);
      });
  };

  const processText = async (text: string) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramValue = urlParams.get('r');
    // @ts-ignore
    const dashIndex = paramValue.indexOf("-");
    // @ts-ignore
    const numberPart = paramValue.substring(dashIndex + 1);
    const extractedNumber = parseInt(numberPart, 10);
    try {
      const res = await fetch('https://api.estecharat.com/api/reports/addContent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ appointmentId: extractedNumber, content: text }),
      });
      const data = await res.text();
      // setResponse(data);
    } catch (error) {
      console.error('There was an error!', error);
    }

    // const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
    // const targetUrl =
    //   'https://application-form-gen.1ew5kcczd38l.ca-tor.codeengine.appdomain.cloud/process_text?text=' + text;
    //
    // try {
    //   const response = await fetch(targetUrl);
    //   if (!response.ok) {
    //     throw new Error('Network response was not ok');
    //   }
    //   const data = await response.json();
    //   console.log(data);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  return <div></div>;
}
