import React from 'react';

export default function CaptionIcon() {
  return (
    /*    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.723858 0.223858 0.5 0.5 0.5H18.5C18.7761 0.5 19 0.723858 19 1V13C19 13.2761 18.7761 13.5 18.5 13.5H9.3096L6.32421 16.361C6.1796 16.4996 5.96624 16.5385 5.78202 16.4599C5.59779 16.3813 5.47826 16.2003 5.47826 16V13.5H0.5C0.223858 13.5 0 13.2761 0 13V1ZM1 1.5V12.5H5.97826C6.2544 12.5 6.47826 12.7239 6.47826 13V14.8283L8.76274 12.639C8.85583 12.5498 8.97977 12.5 9.1087 12.5H18V1.5H1Z"
        fill="#707578"
      />
    </svg>*/
    <svg width="18px" height="16px" viewBox="0 0 18 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>closed_caption</title>
      <desc>Created with Sketch.</desc>
      <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Outlined" transform="translate(-613.000000, -910.000000)">
          <g id="Av" transform="translate(100.000000, 852.000000)">
            <g id="Outlined-/-AV-/-closed_caption" transform="translate(510.000000, 54.000000)">
              <g>
                <polygon id="Path" points="0 0 24 0 24 24 0 24" />
                <path
                  d="M19,4 L5,4 C3.89,4 3,4.9 3,6 L3,18 C3,19.1 3.89,20 5,20 L19,20 C20.1,20 21,19.1 21,18 L21,6 C21,4.9 20.1,4 19,4 Z M19,18 L5,18 L5,6 L19,6 L19,18 Z M7,15 L10,15 C10.55,15 11,14.55 11,14 L11,13 L9.5,13 L9.5,13.5 L7.5,13.5 L7.5,10.5 L9.5,10.5 L9.5,11 L11,11 L11,10 C11,9.45 10.55,9 10,9 L7,9 C6.45,9 6,9.45 6,10 L6,14 C6,14.55 6.45,15 7,15 Z M14,15 L17,15 C17.55,15 18,14.55 18,14 L18,13 L16.5,13 L16.5,13.5 L14.5,13.5 L14.5,10.5 L16.5,10.5 L16.5,11 L18,11 L18,10 C18,9.45 17.55,9 17,9 L14,9 C13.45,9 13,9.45 13,10 L13,14 C13,14.55 13.45,15 14,15 Z"
                  id="🔹-Icon-Color"
                  fill="#1D1D1D"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
